import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';

import {
  composeValidators,
  autocompletePlaceSelected,
  autocompleteSearchRequired,
} from '../../../util/validators';

import css from './PasswordEntryForm.module.css';

const PasswordEntryFormComponent = props => (
  <FinalForm
    {...props}
    initialValues={{ ...props.initialValues, isPasswordEntryForm: true }}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        loginOrSignupError,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {/* <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            /> */}
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="current-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={validators.composeValidators(passwordRequired)}
            />
          </div>
          <div className={css.bottomWrapper}>
            <div className={css.errorMessage}>{loginOrSignupError}</div>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              Continue
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

PasswordEntryFormComponent.defaultProps = { inProgress: false };

PasswordEntryFormComponent.propTypes = {
  inProgress: bool,
  // termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PasswordEntryForm = compose(injectIntl)(PasswordEntryFormComponent);
// WaitlistForm.displayName = 'WaitlistForm';

export default PasswordEntryForm;
