import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ExtendedCustomSectionHero.module.css';
import loginPageImage from '../../assets/Onboarding-Profile-CreateListing.jpeg';
import { ReactComponent as Lock } from '../../assets/lock.svg';
import { ReactComponent as FleggsPinkSvg } from '../../assets/fleggsPinkSvg.svg';
import { ReactComponent as FleggsGreenSvg } from '../../assets/fleggsGreenSvg.svg';
import WaitlistForm from '../../containers/AuthenticationPage/WaitlistForm/WaitlistForm';
import PasswordEntryForm from '../../containers/AuthenticationPage/PasswordEntryForm/PasswordEntryForm';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { storeDataToLocalStorage } from '../../util/data';
import Modal from '../Modal/Modal';

const CustomModal = props => {
  const {
    signupData,
    closeCustomModal,
    isCustomModalOpen,
    setIsCustomModalOpen,
    handleFormSubmission,
    isPasswordEntryFormOpen,
    submitStatus,
  } = props;

  return (
    <React.Fragment>
      <Modal
       className={css.waitlistModal}
       id="contactUsModal"
       isOpen={isCustomModalOpen}
       onClose={() => closeCustomModal(false)}
       // showAsModalMaxWidth={MODAL_BREAKPOINT}
       onManageDisableScrolling={()=>{}}
       usePortal
      >
      <div className={css.customModalContainer}>
          <div className={css.content}>
            <div className={css.joinOrSignParent}>
              <div className={css.joinOrSignImage}>
                <img src={loginPageImage} />
              </div>
              <div className={css.joinOrSignAuthentication}>
                <div onClick={closeCustomModal} className={css.close}>
                  close
                </div>
                <div className={css.signupformMain}>
                  <div className={css.totalContent}>
                    {isPasswordEntryFormOpen && (
                      <div className={css.passwordEntryFormHeader}>
                        <FleggsGreenSvg />
                      </div>
                    )}
                    <div className={css.joinNowContent}>
                      {isPasswordEntryFormOpen ? (
                        <PasswordEntryForm
                          className={css.customForm}
                          onSubmit={handleFormSubmission}
                          termsAndConditions={null}
                          inProgress={submitStatus.submitting}
                          loginOrSignupError={submitStatus.error}
                        />
                      ) : submitStatus.success ? (
                        <div className={css.confirmationContainer}>
                          <FleggsGreenSvg />
                          <div>
                            <div>Thank you for your interest.</div>
                            <div>You're now on the waitlist</div>
                          </div>
                          <div>We'll email you as soon as a spot opens up!</div>
                        </div>
                      ) : (
                        <div className={css.signupFormWrapper}>
                          <div className={css.signinHeader}>Join the Waitlist</div>
                          <div className={classNames(css.signinHeader, css.MobsigninHeader)}>
                            Join the Waitlist
                          </div>
                          <WaitlistForm
                            className={css.customForm}
                            onSubmit={handleFormSubmission}
                            inProgress={submitStatus.submitting}
                            termsAndConditions={null}
                            loginOrSignupError={submitStatus.error}
                          />
                        </div>
                      )}
                    </div>
                    {isPasswordEntryFormOpen && (
                      <div className={css.passwordEntryFormFooter}>
                        By joining, you agree to the Fleggs Terms of Service and to occasionally
                        receive emails from us. Please read our Privacy Policy to learn how we use
                        your personal data.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* {isCustomModalOpen && (
        <div className={css.customModalContainer}>
          <div className={css.content}>
            <div className={css.joinOrSignParent}>
              <div className={css.joinOrSignImage}>
                <img src={loginPageImage} />
              </div>
              <div className={css.joinOrSignAuthentication}>
                <div onClick={closeCustomModal} className={css.close}>
                  close
                </div>
                <div className={css.signupformMain}>
                  <div className={css.totalContent}>
                    {isPasswordEntryFormOpen && (
                      <div className={css.passwordEntryFormHeader}>
                        <FleggsGreenSvg />
                      </div>
                    )}
                    <div className={css.joinNowContent}>
                      {isPasswordEntryFormOpen ? (
                        <PasswordEntryForm
                          className={css.customForm}
                          onSubmit={handleFormSubmission}
                          termsAndConditions={null}
                          inProgress={submitStatus.submitting}
                          loginOrSignupError={submitStatus.error}
                        />
                      ) : submitStatus.success ? (
                        <div className={css.confirmationContainer}>
                          <FleggsGreenSvg />
                          <div>
                            <div>Thank you for your interest.</div>
                            <div>You're now on the waitlist</div>
                          </div>
                          <div>We'll email you as soon as a spot opens up!</div>
                        </div>
                      ) : (
                        <div className={css.signupFormWrapper}>
                          <div className={css.signinHeader}>Join the Waitlist</div>
                          <div className={classNames(css.signinHeader, css.MobsigninHeader)}>
                            Join the Waitlist
                          </div>
                          <WaitlistForm
                            className={css.customForm}
                            onSubmit={handleFormSubmission}
                            inProgress={submitStatus.submitting}
                            termsAndConditions={null}
                            loginOrSignupError={submitStatus.error}
                          />
                        </div>
                      )}
                    </div>
                    {isPasswordEntryFormOpen && (
                      <div className={css.passwordEntryFormFooter}>
                        By joining, you agree to the Fleggs Terms of Service and to occasionally
                        receive emails from us. Please read our Privacy Policy to learn how we use
                        your personal data.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </React.Fragment>
  );
};

const ExtendedCustomSectionHero = props => {
  const { rootClassName, className } = props;
  const [mounted, setMounted] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isPasswordEntryFormOpen, setIsPasswordEntryFormOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    submitting: false,
    error: null,
    data: null,
    success: false,
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const openCustomModal = () => {
    setIsCustomModalOpen(true);
    setIsPasswordEntryFormOpen(true);
  };

  const closeCustomModal = () => {
    setIsCustomModalOpen(false);
    setIsPasswordEntryFormOpen(false);
  };

  const handleFormSubmission = async value => {
    setSubmitStatus({ data: value, submitting: true, error: null, success: false });
    if (submitStatus.submitting) return;
    try {
      const { isWaitingListForm, isPasswordEntryForm, ...restValue } = value;
      const endPath = isWaitingListForm ? 'join' : isPasswordEntryForm ? 'verify' : '';
      const url = `${apiBaseUrl()}/api/waiting-list/${endPath}`;
      await axios.post(url, restValue);
      setSubmitStatus(submitStatus => ({
        ...submitStatus,
        data: null,
        success: true,
        submitting: false,
      }));
      if (isPasswordEntryForm) {
        storeDataToLocalStorage('waitingListPasswordSubmitted', true);
        window.location.reload();
      }
    } catch (e) {
      setSubmitStatus(submitStatus => ({
        ...submitStatus,
        submitting: false,
        error: e?.response?.data || e.message,
      }));
    }
  };

  return (
    <div className={classes}>
      <CustomModal
        signupData={submitStatus.data}
        closeCustomModal={closeCustomModal}
        isCustomModalOpen={isCustomModalOpen}
        setIsCustomModalOpen={setIsCustomModalOpen}
        handleFormSubmission={handleFormSubmission}
        isPasswordEntryFormOpen={isPasswordEntryFormOpen}
        submitStatus={submitStatus}
      />

      <div className={css.heroContentContainer}>
        <div className={css.heroContentTop}>
          <FleggsPinkSvg />
          <div onClick={openCustomModal}>
            <span>Enter with Password</span>
            <Lock />
          </div>
        </div>

        <div className={css.heroContent}>
          <div>
            <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <FormattedMessage id="CustomSectionHero.title" />
            </h1>
            <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="CustomSectionHero.subTitle" />
            </h2>
            <a onClick={() => setIsCustomModalOpen(true)} className={css.signuplinkText}>
              <FormattedMessage id="CustomTopbar.JoinTheWaitlist" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ExtendedCustomSectionHero.defaultProps = { rootClassName: null, className: null };

ExtendedCustomSectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default ExtendedCustomSectionHero;
