import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldLocationAutocompleteInput,
  FieldRadioButton,
} from '../../../components';

import {
  composeValidators,
  autocompletePlaceSelected,
  autocompleteSearchRequired,
} from '../../../util/validators';

import css from './WaitlistForm.module.css';

const identity = v => v;

const WaitlistFormComponent = props => {
  const [isRadioButtonChecked, setIsRadioButtonChecked] = useState(false);

  return (
    <FinalForm
      {...props}
      initialValues={{ ...props.initialValues, isWaitingListForm: true }}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          loginOrSignupError,
          values,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !isRadioButtonChecked;

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div className={css.firstAndLastNameRootContainer}>
                <FieldTextInput
                  className={css.firstAndLastNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.firstAndLastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              <FieldTextInput
                className={css.email}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.errorMessage}>{loginOrSignupError}</div>
              <div className={css.locationFieldContainer}>
                <FieldLocationAutocompleteInput
                  rootClassName={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  autoFocus={null}
                  name="location"
                  label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingLocationForm.addressPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                <div
                  onClick={() => setIsRadioButtonChecked(true)}
                  className={css.radioButtonWrapper}
                >
                  <FieldRadioButton
                    id={formId ? `${formId}.customer` : 'customer'}
                    label={intl.formatMessage({
                      id: 'WaitlistForm.customUserTypeCustomer',
                    })}
                    name="customUserType"
                    value="customer"
                  />
                  <FieldRadioButton
                    id={formId ? `${formId}.trainer` : 'trainer'}
                    label={intl.formatMessage({
                      id: 'WaitlistForm.customUserTypeTrainer',
                    })}
                    name="customUserType"
                    value="trainer"
                  />
                </div>
              </div>
            </div>

            <div className={css.bottomWrapper}>
              {/* {termsAndConditions} */}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="WaitlistForm.confirm" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

WaitlistFormComponent.defaultProps = { inProgress: false };

WaitlistFormComponent.propTypes = {
  inProgress: bool,
  // termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const WaitlistForm = compose(injectIntl)(WaitlistFormComponent);
// WaitlistForm.displayName = 'WaitlistForm';

export default WaitlistForm;
