import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { LayoutSingleColumn, NamedLink, NamedRedirect, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import ExtendedCustomSectionHero from '../../components/CustomSectionHero/ExtendedCustomSectionHero';
import css from './InitialLandingPage.module.css';
import SectionCommunityFavourites from '../../components/SectionCommunityFavourites/SectionCommunityFavourites';
import SectionContactUs from '../../components/SectionContactUs/SectionContactUs';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import classNames from 'classnames';
import { getListingsById } from '../../ducks/marketplaceData.duck';
const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const InitialLandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled,
    listings,
    currentUser,
    isAuthenticated,
  } = props;
  // if (currentUser?.attributes?.profile?.publicData?.isTrainer === undefined && isAuthenticated) {
  //   return <NamedRedirect name="SignupPage" />;
  // }
  const intl = useIntl();

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' });
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbar={<></>}
        // topbar={<TopbarContainer customAnimation={true} />}
        mainColumnClassName={classNames(css.layoutWrapperMain, css.LandingpagelayoutMain)}
        // footer={<FooterComponent />}
      >
        <ExtendedCustomSectionHero/>
        {/* <div className={css.landingPageContent}>
          <div className={css.aboutFleggsDiv}>
            <span>About Fleggs</span>
            <p>
              Experience fitness for what it can be, not what it should be with workouts that
              champion you. Connect with the world's best fitness communitites to train whenever and
              however with Fleggs.
            </p>
          </div>
          <SectionCommunityFavourites listings={listings} />

          <div className={css.trianerQuoteDiv}>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.”
            </p>
            <span>Trainer Name</span>
          </div>
          <SectionContactUs />
          <div className={css.startYourJourneyDiv}>
            <h1>Join the Fleggs Family</h1>
            <p>List your profile and start connecting with new communities and customers today</p>
            <NamedLink name="SearchPage" className={css.startYourJourneyBtn}>
              <span className={css.topbarLinkLabel}>
                <FormattedMessage id="CustomTopbar.StartYourJourney" />
              </span>
            </NamedLink>
          </div>
        </div>
        <CustomFooter /> */}
      </LayoutSingleColumn>
    </Page>
    // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage error={error} />}
    // />
  );
};

InitialLandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  const { pageAssetsData, inProgress, error, currentPageResultIds } = state.hostedAssets || {};
  const listings = getListingsById(state, currentPageResultIds);
  return {
    isAuthenticated,
    currentUser,
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled: isScrollingDisabled(state),
    listings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const InitialLandingPage = compose(connect(mapStateToProps))(InitialLandingPageComponent);

export default InitialLandingPage;
